import {
	IPaymentCompletedResponse,
	IProcessingPaymentParameters,
} from "../payments.typings";

export const paymentProcessingState = {
	paymentParameters: {
		paymentResponse: {} as IPaymentCompletedResponse,
	} as IProcessingPaymentParameters,
};

const paymentProcessingReducer = () => {
	return {
		triggerPaymentProcessing: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					identifier: payload.identifier,
					paymentRequestCompletedSuccessfully: false,
					paymentRequestFailureMessage: "",
					paymentAmount: 0,
				},
			};
		},
		setPaymentConfirmationFailure: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					paymentResponse: {
						...state.paymentParameters.paymentResponse,
						isSuccess: false,
						message: payload,
						requiredActionFailed: true,
					} as IPaymentCompletedResponse,
				} as IProcessingPaymentParameters,
			};
		},
		setPaymentProcessingResult: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					paymentAcceptanceId: 0, //we must set this 0 to do not trigger any other payment form
					paymentRequestCompletedSuccessfully: payload.success, //api call success
					paymentResponse: {
						isSuccess: payload.isSuccess, //payment processing success
						orderBalanceDue: payload.orderBalanceDue,
						isPartialPayment: payload.isPartialPayment,
						message: payload.message,
						changeDue: payload.changeDue,
						paymentAmount: payload.paymentAmount,
						requiresAction: payload.requiresAction,
						clientSecret: payload.clientSecret,
					} as IPaymentCompletedResponse,
				} as IProcessingPaymentParameters,
			};
		},
		resetPaymentProcessingResult: (state) => {
			return {
				...state,
				paymentParameters: {
					paymentResponse: {} as IPaymentCompletedResponse,
				} as IProcessingPaymentParameters,
			};
		},
		setPaymentProcessingApiError: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					paymentAcceptanceId: 0, //we must set this 0 to do not trigger any other payment form
					paymentRequestCompletedSuccessfully: false, //api call success
					paymentRequestFailureMessage: payload.errorMessage,
					paymentResponse: {},
				},
			};
		},
		setPaymentProcessingAmount: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					paymentAmount: payload,
				},
			};
		},
		setPaymentFormInvalid: (state, { payload }) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					paymentFormInvalid: payload,
				},
			};
		},
		triggerFormInvalid: (state) => {
			return {
				...state,
				paymentParameters: {
					...state.paymentParameters,
					lastFormInvalidTriggerDate: new Date(),
				},
			};
		},
	};
};
export default paymentProcessingReducer;
