import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next)
	.use(Backend)
	.init({
		debug: true,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
